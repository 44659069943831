#map {
    width: 100%;
    height: 100%;
}

#infowindow-listing-card {
    height: 308px;
    width: 327px;
    text-align: left;
    overflow: hidden;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
}

#map-marker-img {
    height: 20px;
    width: 40px;
    /* background-color: white; */
    /* color: black; */
}


/* Editing inherit Google Maps Infowindow  */
.gm-style .gm-style-iw.gm-style-iw-c {
    box-shadow: 5px 5px 20px gray;
    padding: 0;
    border-radius: 20px;
    /* overflow: auto; */
}

.gm-style .gm-style-iw.gm-style-iw-c .gm-style-iw-d {
    overflow: visible !important;
}

button.gm-ui-hover-effect {
    left: 15px;
    top: 15px !important;
    border-radius: 15px;
    background-color: rgba(0,0,0,.5) !important;
}

