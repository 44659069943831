#modal-content {
    height: 680px;
    width: 570px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 12px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    animation: popin .6s ease-in;
}

@keyframes popin {
    0%{
        transform: translateY(80%);
    }
    100%{
        transform: translateY(0)
    }
}

#login-or-signup {
    /* display: block; */
    border-bottom: 1px solid lightgray;
    width: 100%;
    text-align: center;
    font-weight: bold;
    padding-bottom: 15px;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    font-family: 'Montserrat', sans-serif;
}

#welcome {
    width: 90%;
    font-size: 20px;
    font-weight: bold;
    margin: 30px 0px;
}

.user-auth-button, .demo-button {
    cursor: pointer;
    background-color: rgb(229, 30, 85);
    color: white;
    border: none;
    border-radius: 5px;
    font-family: inherit;

    
}

.user-auth-button, .user-auth-entry, .demo-button{
    height: 50px;
    border: 1px solid gray;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    margin-bottom: 10px;
}

.user-auth-button, .demo-button{
    border: none;
}

.demo-button{
    width: 90%
}

#github-link-container{
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 5px;
    height: 50px;
    margin-top: 10px;
    background-color: white;
    text-decoration: none;
    color: inherit;
}

#linkedin-link-container{
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 5px;
    height: 50px;
    margin-top: 10px;
    background-color: white;
    text-decoration: none;
    color: inherit;
}

#linkedin-button, #github-button{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#linkedin-button:hover, #github-button:hover {
    background-color: lightgray;
}

#or-container > p {
    text-decoration: line-through;
}