#listings-page div {
    font-family: 'Montserrat', sans-serif;
}

#listings-page-wrapper {
    margin-top: 80px;
}

h1 {
    font-size: 26px;
}

.small-img {
    height: 100%;
    width: 100%;
}

#top-right-pic {
    border-top-right-radius: 15px;
}

#bottom-right-pic {
    border-bottom-right-radius: 15px;
}

.main-img {
    height: 100%;
    width: 100%;
    grid-column: 1;
    grid-row: 1 / 3;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

#images-wrapper {
    display: flex;
    justify-content: center;
}

#bottom-portion {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

#information-wrapper {
    margin-left: 14%;
    margin-right: 14%;
    width: 100%;
    display: flex;
    border-bottom: 1px solid lightgray;
}

#images-container {
    height: 520px;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 25% 25%;
    grid-template-rows: 47% 47%;
    grid-auto-flow: column;
    gap: 10px;
    margin-left: 14%;
    margin-right: 14%;
}

#title-container {
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 14%;
    height: 90px;
    color: black;
}

#title {
    font-weight: bold;
    width: 100%;
    height: 20px;
}

#title-container h1 {
    font-weight: bold;
}

#information-container {
    width: 58%;
}

#hosted-by-container {
    border-bottom: 1px solid lightgray;
    padding-top: 25px;
    padding-bottom: 25px;
    height: 52px;
    width: 100%;
}

#hosted-by-container h1 {
    font-size: 22px;
    font-weight: bold;
    padding-bottom: 5px;
}

#calender-container {
    border: 1px solid lightgray;
    margin-left: 8%;
    width: 34%;
    height: 490px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 100px;
}

#form-reservation-container {
    border: 1px solid lightgray;
    margin-left: 8%;
    width: 34%;
    height: 490px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

}

#highlights-wrapper {
    padding-top: 38px;
    border-bottom: 1px solid lightgray;
    height: 215px;
    width: 100%;

}

#air-cover-container {
    border-bottom: 1px solid lightgray;
    width: 100%;
    height: 180px;
}

#description-container {
    display: flex;
    border-bottom: 1px solid lightgray;
    padding-top: 40px;
    padding-bottom: 40px;
}

#icons-container {
    padding-top: 50px;
    height: 380px;
    width: 100%;
}

#icons-container h2 {
    font-size: 22px;
    font-weight: bold;
    padding-bottom: 25px;
}

#icons-list {
    display: flex;
    flex-wrap: wrap;
}

.offered-icons {
    height: 50px;
    width: 50%;
    color: black;
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.highlight-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

#reviews-wrapper {
    margin-left: 14%;
    margin-right: 14%;
    width: 100%;
}

#review-stats {
    height: 240px;
}

#review-stats h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 10px;
}

#write-review {
    margin-bottom: 40px;
    font-size: 20px;
}

.review-card {
    font-family: 'Montserrat', sans-serif;
    width: 80%;
    padding-right: 20%;
    height: 196px;
}

.reviewer-name h2 {
    font-size: 20px;
    font-weight: bold;
}

#edit-remove-container {
    display: flex;
    justify-content: space-between;
    width: 20%;
}

.reviewer-name {
    height: 40px;
    padding-bottom: 15px;
}

.highlight-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.highlight-text-container {
    display: flex;
    flex-direction: column;
}

.highlight-container h2 {
    width: 100%;
    height: 50%
}

.highlight-icon-container {
    height: 100%;
    margin-right: 3%;
}

.highlight-text-container h2 {
    font-weight: bold;
    padding-bottom: 1%
}

.highlight-text-container div {
    font-size: 14px;
    color: gray
}

.highlight-container .fa-solid {
    position: relative;
    top: 6px;
}

#edit,
#remove {
    text-decoration: none;
    color: black;
}