html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
  list-style-type: none;
}

#listings-page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
    
}

#listings-grid{
  display: grid;
  padding: 15px;
  padding-top: 15px;
  column-gap: 22px;
  row-gap: 30px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
  grid-template-columns: 1fr 1fr 1fr 1fr;

}

#listings-map{
  height: 100%;
}

#listing-card {
  height: 430px;
  text-align: left;
  overflow: hidden;
  font-family: inherit;
  cursor: pointer;
}

#index-listing-city-country {
  font-weight: bold;
}

#map-listing-city-country {
  position: relative;
  font-weight: bold;
  left: 5%;
}

#listing-price {
  font-weight: bold;
}

#index-listing-profile-photo {
  height: 72%;
  width: 100%;
  border-radius: 25px;
  padding-bottom: 10px;
}

#map-listing-profile-photo {
  height: 50%;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-bottom: 10px;
}


#index-listing-price-line {
  position: relative;
  top: 8px;
}

#map-listing-price-line {
  position: relative;
  top: 8px;
  left: 5%;
}

#map-hosted-by-line{
  position: relative;
  left: 5%;
}

#map-date-line{
  position: relative;
  left: 5%;
}

@media (min-width: 740px) {

    #listings-grid{
      padding-left: 80px;
      padding-right: 80px;
      padding-top: 15px;
    }

}

div:link {
    text-decoration: none;
}

/* Future component carousel css */

/* #carousel-container{
    height: 95px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    COMMENTED OUT: border-bottom: 1px solid lightgray;
    box-shadow: 0 1px 4px lightgray;
    
} */


.icon-container {
    position: relative;
    height: 100%; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.icon-container .fa-solid {
    position: relative;
    top: 10px;
    color: gray
}


.icon-container:hover{
    border-bottom: 2px solid lightgray;
}


.icon-container p {
    position: relative;
    top: 25px;
    color: gray
}

.icon-container:hover .fa-solid{
    color: black;
}

.icon-container:hover p{
    color: black;
}

li {
    color: red;
}

#show-map-button{
    color: white;
    background-color: rgb(51, 46, 46);
    border: none;
    min-height: 50px;
    width: 150px;
    border-radius: 20px;
    position:sticky;
    bottom: 60px;
    left: 46%;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

#show-map-button:hover{
    cursor: pointer;
}

#index-picture-arrows-container{
    position: relative;
    bottom: 40%;
}

#map-picture-arrows-container{
  position: relative;
  bottom: 30%;
}

.picture-arrows {
    background-color: white;
    border: none;
    border-radius: 20px;
    position: absolute;
    transform: translateY(-40%);
    cursor: pointer;
    height: 30px;
    width: 30px;
}

#left-arrow{
    left: 10%;
}

#right-arrow{
    left: 85%;
}



@keyframes slideOutRight {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(100%);
      opacity: 0;
    }
  }
  
  @keyframes slideOutLeft {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(-100%);
      opacity: 0;
    }
  }
  
  @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideInRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .listing-photo {
    animation-duration: 0.6s; /* Adjust the animation duration as needed */
  }
  
  .slide-out-left {
    animation-name: slideOutLeft;
    /* animation-fill-mode: forwards; */
  }
  
  .slide-out-right {
    animation-name: slideOutRight;
    /* animation-fill-mode: forwards; */
  }
  
  .slide-in-left {
    animation-name: slideInLeft;
    /* animation-fill-mode: forwards; */
  }
  
  .slide-in-right {
    animation-name: slideInRight;
    /* animation-fill-mode: forwards; */
  }