#navigation-wrapper {
    border-bottom: 1px solid lightgray;
    font-family: 'Montserrat', sans-serif;
    position: fixed;
    top: 0;
    background-color: white;
    width: 100%;
    z-index: 1;
}

.navigation-bar {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    height: 80px;
    width: 85%;
    align-items: center;
    margin-left: 5%;
    background-color: white;
}



#profile-dropdown {
    font-size: 20px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
    background-color: white;
}

.account-button {
    position: fixed;
    border: 1px solid lightgray;
    background-color: white;
    border-radius: 21px;
    height: 42px;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: box-shadow 0.2s ease;
}

.account-button:hover {
    box-shadow: 0 2px 4px gray;
}

#hamburger,
#account-icon {
    position: relative;
    top: 25%;
    height: 20px;
    width: 30px;
}


#airbnb-logo {
    height: 30px;
    width: 100px;
}

#searchbar {
    border: none;
    border-radius: 15px;
    font-size: 15px;
    width: 250px;
    font-weight: bold;
    height: 95%;
    outline: none;
}

#searchbar-container {
    display: flex;
    border: 1px solid lightgray;
    background-color: white;
    border-radius: 21px;
    height: 48px;
    transition: box-shadow 0.2s ease;
    width: 330px;

    align-items: center;
    font-size: 16px;
    font-weight: bold;
    justify-content: space-around;


}

#search-button {
    border-radius: 100px;
    background-color: red;
    height: 40px;
    width: 40px;
    border: none;
}

.fa-magnifying-glass {
    color: white;
}

#searchbar-container:hover {
    box-shadow: 0 2px 4px gray;
}

#profile-button-container {
    height: 48px;
    width: 80px;
}



#profile-dropdown {
    font-size: 16px;
    width: 250%;
    position: relative;
    top: 50px;
    right: 130px;
    background-color: white;
    border-radius: 10px;
    padding-top: 10%;
    padding-bottom: 10%;
}

#login-button,
#signup-button,
#logout-button,
#trips-button {
    padding-left: 5%;
    padding-top: 4%;
    padding-bottom: 4%;
    background-color: white;
}

#login-button:hover,
#signup-button:hover,
#logout-button:hover,
#trips-button:hover {
    background-color: lightgray;
    cursor: pointer;
}

.icon-container {
    cursor: pointer;
}

#linkedin-logo,
#github-logo {
    height: 30px;
    width: 30p;
    padding-left: 10px;
}