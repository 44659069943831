#modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
  
#modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    /* z-index: 2; */
}
  
#modal-content {
    position: absolute;
    background-color:white;
    /* z-index: 2; */
}

#exit-button {
    position:relative;
    left: 20px;
    top: 20px;
    cursor: pointer;
    font-size: 35px;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    /* background-color: lightgray; */
    align-self:self-start;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;

}

#exit-button:hover {
    background-color: lightgray;
}